import * as React from "react";
import { useEffect, useState } from "react";
import { FC, Fragment } from "react";
import {
  Filter,
  SearchInput,
  SelectInput,
  downloadCSV,
  TopToolbar,
  ExportButton,
  required,
  DateTimeInput,
  useDataProvider,
  Link,
} from "react-admin";
import {
  Drawer,
  useMediaQuery,
  Theme,
  makeStyles,
  useTheme,
  Button,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { Route, RouteChildrenProps } from "react-router-dom";
import { ListComponentProps } from "../types";
import GridList from "./GridList";
import jsonExport from "jsonexport/dist";
import { auth } from "../helperChoice";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Form } from "react-final-form";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { Cards } from "../common";
import {
  absentMember,
  memberOnLeave,
  presentMember,
  totalMember,
} from "../img";
import { toastError, toastInfo } from "../components/toast";
import LinkField from "./LinkField";
import HistoryIcon from "@material-ui/icons/History";
import DialogueBox from "../dashboard/DialogueBox";
import { stringify } from "query-string";
import { Loader } from "semantic-ui-react";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import GetAppIcon from "@material-ui/icons/GetApp";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";
const PostFilterForm = (data: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.time_from) {
        values.time_from = moment.utc(moment(values.time_from).utc()).format();
      }
      if (values.time_to) {
        values.time_to = moment.utc(moment(values.time_to).utc()).format();
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };
  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["time_from"] = helperFunc.subtractDaysFilter(1);
    myFilters["time_to"] = helperFunc.addDaysFilter(0);
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const resetFilter = () => {
    data.setFilters(filterData(), []);
    data.handleDrawerClose("fromFilter");
  };

  const checkFromDate = (value: any, allValues: any) => {
    if (allValues.time_from > new Date()) {
      return "From date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "From date cannot be greater then To date";
    }
    return [];
  };

  const checkToDate = (value: any, allValues: any) => {
    if (allValues.time_to > new Date()) {
      return "To date cannot be greater then current date";
    }
    if (allValues.time_from > allValues.time_to) {
      return "To date cannot be Smaller then From date";
    }
    return [];
  };
  const drawerWidth = 350;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    textRed: { color: "red" },
  }));

  const theme = useTheme();
  const classes = useStyles();
  const user = helperFunc.getUserData();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form onSubmit={onSubmit} initialValues={data.filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <CompanyReferenceInput
                        helperText={false}
                      ></CompanyReferenceInput>
                    ) : null}
                  </Box>
                </Box>

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable={true}
                      allowEmpty={false}
                      source="authType"
                      choices={auth}
                      helperText={false}
                    />
                  </Box>
                </Box>
                <Box alignItems="flex-start">
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root}  >
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      // formClassName={isSmall ? null : classes.code}
                      label="label.fromDate"
                      source="time_from"
                      validate={[required(), checkFromDate]}
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>
                <Box alignItems="flex-start" mb={1}>
                  <Box component="span">
                    {/* <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => ( */}
                    <DateTimeInput
                      options={{
                        format: helperFunc.commonDateFormat("HH:mm:ss"),
                        ampm: true,
                        clearable: true,
                      }}
                      // defaultValue={helperFunc.addDays(1, "forReport")}
                      validate={[required(), checkToDate]}
                      source="time_to"
                      label="label.toDate"
                      alwaysOn
                      helperText={false}
                    />
                    {/* )}
                    </FormDataConsumer> */}
                  </Box>
                </Box>

                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};
const ListActions = (props: any) => {
  const {
    basePath,
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <TopToolbar>
      {
        // <Button
        //   className="btnfilter"
        //   color="primary"
        //   component="span"
        //   // onClick={handleTeamHistory}
        //   style={
        //     isSmall
        //       ? {
        //           fontSize: "0.8125rem",
        //         }
        //       : {
        //           fontSize: "0.8125rem",
        //           minWidth: "126px",
        //         }
        //   }
        // >
        //   <HistoryIcon style={{ marginRight: "0.2em" }} />
        //   {!isXSmall ? (
        //     <span style={{ paddingLeft: "0.2em" }}>History</span>
        //   ) : null}
        // </Button>
      }
      <Button
        className="btnfilter"
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        name={isSmall ? null : "Export"}
      />
      {/* {user.designation === "HR" ||
      user.type === "super" ||
      user.type === "company" ? (
        <ImportButton {...props} />
      ) : null} */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const useStyles = makeStyles({
  root: {
    marginTop: "0em",
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },
  drawerPaper: {
    zIndex: 100,
  },
  textRed: { color: "red" },
  userImg: {
    padding: 0,
    height: 25,
    width: 25,
    marginLeft: 10,
    borderRadius: "30px",
  },
  logo: { width: "100%", height: "inherit" },
  descriptionIcon: {
    transform: "rotate(180deg)",
    fontSize: 20,
  },
  smCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
  },
  lgCsvButton: {
    padding: "4px 5px !important",
    fontSize: " 0.8125rem !important",
    minWidth: "126px",
  },
  lgViewCsvButton: {
    fontSize: " 0.8125rem !important",
    marginBottom: "-3px",

    // marginTop: 10,
    // minWidth: "126px",
  },
  csvSpan: { paddingLeft: "0.5em" },
  exportButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  viewButton: {
    padding: "0 !important",
    fontSize: 20,
    marginTop: "0.3em",
  },
  exportIcon: {
    fontSize: 20,
  },
  viewIcon: {
    fontSize: 24,
  },
});

const trimValue = (v: any) => {
  return v.replace(/^\s+/, "");
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    {helperFunc.getUserData().designation === "HR" ||
    helperFunc.getUserData().type === "super" ||
    helperFunc.getUserData().type === "company" ? (
      <SearchInput source="employeeNameCode" parse={trimValue} alwaysOn />
    ) : null}
    <PostFilterForm {...props} alwaysOn />
  </Filter>
);

const EmergencyList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const [open, setOpen] = useState(false);
  const [dialogueOpen, setDialogueOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [activeSlice, setActiveSlice] = useState() as any;
  const dataProvider = useDataProvider();
  const [countData, setCountData] = useState() as any;
  const [cardCountData, setCardCountData] = useState() as any;
  const [loading, setLoading] = useState(false);
  const [listView, setListView] = useState(true);
  const [isSafeTrigger, setIsSafeTrigger] = useState();

  const approvalStatusEmployeeType: any = {
    visitor: "Visitor",
    employee: "Employee",
  };

  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const DEVICE_LOCATION = { in: "In", out: "Out" } as any;

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  let container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Emergency";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const handleChangePage = (event: any, newPage: any) => {
    handleDefaultPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    handleRowsPerPage(parseInt(event.target.value, 10));
    handleDefaultPage(0);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const handleDefaultPage = (index: number) => {
    setPage(index);
  };

  const handleRowsPerPage = (index: number) => {
    setRowsPerPage(index);
  };

  const employeeObj = {
    "Total Person": "all",
    "Employee at Assembly Point": "employeeAtAssemblyPoint",
    "Employee Inside Premise": "employeeInsidePremise",
    "Visitor on Site": "allVisitor",
    "Visitor at Assembly Premise": "vistorAtAssemblyPoint",
    "Visitor Inside Premise": "vistorInsidePremise",
  } as any;

  const handleClickOpen = (data: any) => {
    setDialogueOpen(true);
    setActiveSlice(data);
    setCountData({ ...countData, type: employeeObj && employeeObj[data] });
    setPage(0);
  };

  useEffect(() => {
    const url = "emergency-evacuation/list";
    setLoading(true);
    dataProvider
      .callCustom(url, {
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        sortBy: "time",
        sort: "desc",
        filter: {
          time_from: helperFunc.subtractDaysFilter(0),
          time_to: helperFunc?.addDaysFilter(0),
        },
        type: countData?.type ? countData?.type : "all",
      })
      .then((response: any) => {
        setLoading(false);

        setCardCountData(response?.data?.dataCount);

        const responseData = response.data;

        if (activeSlice === "Employee Inside Premise") {
          const updatedData = [] as any;
          responseData?.list?.filter((empData: any, index: any) => {
            responseData?.employeeInsidePremise?.map((presentData: any) => {
              const dateTime = moment(presentData?.time);
              const timeOnly = dateTime.format("DD/MM/YYYY,HH:mm:ss");

              if (presentData?._id === empData?._id) {
                updatedData?.push({
                  authId: empData?.authId,
                  authType:
                    presentData?.authType &&
                    DEVICE_LOCATION[presentData?.authType],
                  time: presentData?.time ? timeOnly : "-",
                  name: empData?.name,
                  profile_image: empData?.profile_image,
                  employeeType:
                    approvalStatusEmployeeType[presentData?.employeeType],
                });
              }
            });
          });

          setCountData({
            ...countData,
            list: updatedData,
            updatedCount: response?.data?.dataCount?.employeeInsidePremise,
          });
        } else if (activeSlice === "Employee at Assembly Point") {
          setCountData({
            ...countData,
            list: responseData?.employeeAtAssemblyPoint,
            updatedCount: response?.data?.dataCount?.employeeAtAssemblyPoint,
          });
        } else if (activeSlice === "Visitor at Assembly Premise") {
          setCountData({
            ...countData,
            list: responseData?.vistorAtAssemblyPoint,
            updatedCount: response?.data?.dataCount?.vistorAtAssemblyPoint,
          });
        } else if (activeSlice === "Visitor Inside Premise") {
          setCountData({
            ...countData,
            list: responseData?.vistorInsidePremise,
            updatedCount: response?.data?.dataCount?.vistorInsidePremise,
          });
        } else
          setCountData({
            ...response.data,
            updatedCount: response?.data?.dataCount?.all,
          });
      })
      .catch((err: any) => {
        err.response.data.errors.forEach((error: any) => {
          toastError(err.message);
          if (error.response.status === 401) {
            window.location.replace("/login");
          }
        });
      });
  }, [
    dataProvider,
    page,
    rowsPerPage,
    activeSlice,
    isSafeTrigger,
    countData?.type,
  ]);

  let updatedTeamData = countData?.list || [];

  countData?.list?.filter((empData: any, index: any) => {
    countData?.employeeInsidePremise?.map((presentData: any) => {
      const dateTime = moment(presentData?.time);
      const timeOnly = dateTime.format("DD/MM/YYYY,HH:mm:ss");
      if (presentData?._id === empData?._id) {
        updatedTeamData[index] = {
          ...updatedTeamData[index],
          authType:
            presentData?.authType && DEVICE_LOCATION[presentData?.authType],
          time: empData?.time ? timeOnly : "-",
          employeeType: approvalStatusEmployeeType[presentData?.employeeType],
          authId: empData?.authId,
        };
      }
    });
    countData?.vistorInsidePremise?.map((absentData: any) => {
      const dateTime = moment(absentData?.time);
      const timeOnly = dateTime.format("DD/MM/YYYY,HH:mm:ss");
      if (absentData?._id === empData?._id) {
        updatedTeamData[index] = {
          ...updatedTeamData[index],
          authType:
            absentData?.authType && DEVICE_LOCATION[absentData?.authType],
          color: "red",
          time: empData?.time ? timeOnly : "-",
          employeeType: approvalStatusEmployeeType[absentData?.employeeType],
          authId: empData?.authId,
        };
      }
    });
    countData?.employeeAtAssemblyPoint?.map((absentData: any) => {
      const dateTime = moment(absentData?.time);
      const timeOnly = dateTime.format("DD/MM/YYYY,HH:mm:ss");
      if (absentData?._id === empData?._id) {
        updatedTeamData[index] = {
          ...updatedTeamData[index],
          authType: DEVICE_LOCATION[absentData.authType],
          color: "red",
          time: empData?.time ? timeOnly : "-",
          employeeType: approvalStatusEmployeeType[absentData?.employeeType],
          authId: empData?.authId,
        };
      }
    });
    countData?.vistorAtAssemblyPoint?.map((absentData: any) => {
      const dateTime = moment(absentData?.time);
      const timeOnly = dateTime.format("DD/MM/YYYY,HH:mm:ss");
      if (absentData?._id === empData?._id) {
        updatedTeamData[index] = {
          ...updatedTeamData[index],
          authType:
            absentData?.authType && DEVICE_LOCATION[absentData?.authType],
          color: "red",
          time: empData?.time ? timeOnly : "-",
          employeeType: approvalStatusEmployeeType[absentData?.employeeType],
          authId: empData?.authId,
        };
      }
    });
  });

  const headerArr = [
    { label: "Images", key: "profile_image", isImage: true },
    { label: "Name", key: "name", isLink: true },
    {
      label: "Location",
      key: "wokplaceData.isEmergency",
      isAuthType: true,
    },
    { label: "Type", key: "employeeType" },
    { label: "Date & Time", key: "time" },
    { label: "Is Safe?", key: "isSafe", isButton: true },
  ];

  const Sampleexporter = () => {
    const recordsForExport = countData?.list?.map((record: any) => {
      return {
        Name: record?.name,
        "Last Status": record?.authType,
        Type: record?.employeeType === "visitor" ? "Visitor" : "Employee",
        "Date & Time": record?.time,
      };
    });
    jsonExport(recordsForExport, (err: any, csv: any) => {
      downloadCSV(csv, "Emergency Report"); // download as 'xxxx.csv` file
    });
  };

  const handleView = () => {
    setListView(!listView);
  };

  const handleIsSafe = (id: any) => {
    if (id) {
      const resourcePath = `emergency-evacuation/mark-employee-safe/${id}`;
      dataProvider
        .callCustom(resourcePath, { isSafe: true })
        .then((response: any) => {
          toastInfo("Marked as safe successfully");

          setIsSafeTrigger(id);
          // setTimeout(() => {
          //   setIsSafeTrigger(false)
          // }, 1000);
        })
        .catch((err: any) => {
          toastError(err);
        });
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className={classes.root}>
          {isXSmall ? (
            <h2
              style={{
                height: 55,
                fontSize: 30,
                backgroundColor: "#fff",
                padding: 10,
              }}
            >
              Attendance
            </h2>
          ) : null}
          <div style={{ display: "flex", marginTop: "2em" }}>
            <Cards
              title="pos.card.total_employee"
              value={countData?.count}
              src={totalMember}
              bgColor="#31708f"
              onClick={() => handleClickOpen("Total Person")}
            />
            <Cards
              title="pos.card.employee_at_assembly_point"
              value={cardCountData?.employeeAtAssemblyPoint}
              src={presentMember}
              bgColor="#FFC107"
              onClick={() => handleClickOpen("Employee at Assembly Point")}
            />
            <Cards
              title="pos.card.employee_inside_premise"
              value={cardCountData?.employeeInsidePremise}
              src={absentMember}
              bgColor="#FF5722"
              onClick={() => handleClickOpen("Employee Inside Premise")}
            />
            {/* <Cards
              title="pos.card.total_visitor"
              value={countData?.vistorAtAssemblyPoint?.length}
              src={memberOnLeave}
              bgColor="#8BC34A"
              onClick={() => handleClickOpen("Visitor on Site")}
            /> */}
            <Cards
              title="pos.card.visitor_at_assembly_premise"
              value={cardCountData?.vistorAtAssemblyPoint}
              src={memberOnLeave}
              bgColor="#8BC34A"
              onClick={() => handleClickOpen("Visitor at Assembly Premise")}
            />
            <Cards
              title="pos.card.visitor_inside_premise"
              value={cardCountData?.vistorInsidePremise}
              src={memberOnLeave}
              bgColor="#8BC34A"
              onClick={() => handleClickOpen("Visitor Inside Premise")}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginTop: "15px",
            }}
          >
            <div>
              <Button
                className={
                  isSmall ? classes.smCsvButton : classes.lgViewCsvButton
                }
                color="primary"
                component="span"
                onClick={handleView}
              >
                {listView ? (
                  <ViewComfyIcon className={classes.viewIcon} />
                ) : (
                  <ListIcon className={classes.viewIcon} />
                )}
                {!isSmall ? (
                  <span className={classes.csvSpan}>
                    {listView ? "Grid" : "List"}
                  </span>
                ) : null}
              </Button>
            </div>

            <Button
              className={isSmall ? classes.smCsvButton : classes.lgCsvButton}
              color="primary"
              component="span"
              onClick={Sampleexporter}
            >
              <GetAppIcon className={classes.exportIcon} />
              {!isSmall ? (
                <span className={classes.csvSpan}>Export</span>
              ) : null}
            </Button>
          </div>

          {listView ? (
            <Fragment>
              <div
                className={open === true ? "overlay" : ""}
                onClick={() => handleDrawerClose("fromlist")}
              />
              <Paper style={{ marginTop: "15px", marginRight: "15px" }}>
                <TableContainer className="team-list">
                  <Table aria-label="simple table" stickyHeader={true}>
                    <TableHead>
                      <TableRow>
                        {headerArr.length > 0
                          ? headerArr.map((item: any) => {
                              return <TableCell>{item?.label}</TableCell>;
                            })
                          : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {countData?.list?.length > 0 ? (
                        countData?.list.map((data: any, index: number) => {
                          return (
                            <TableRow key={index + 1}>
                              {headerArr.map((item: any) => {
                                if (item?.isLink) {
                                  return (
                                    <TableCell>
                                      <Link
                                        to={{
                                          pathname: `/teamattendance`,
                                          search: stringify({
                                            page: 1,
                                            perPage: 10,
                                            sort: "id",
                                            order: "DESC",
                                            filter: JSON.stringify({
                                              employee: data?._id,
                                              time_from:
                                                helperFunc.subtractDaysFilter(
                                                  0
                                                ),
                                              time_to:
                                                helperFunc.addDaysFilter(0),
                                            }),
                                          }),
                                        }}
                                      >
                                        {data?.name}
                                      </Link>
                                    </TableCell>
                                  );
                                } else if (item?.isImage) {
                                  return (
                                    <TableCell className="p-0">
                                      <img
                                        src={
                                          data.profile_image &&
                                          uploadApiUrl &&
                                          uploadApiUrl + data.profile_image
                                            ? uploadApiUrl &&
                                              uploadApiUrl + data?.profile_image
                                            : "assets/img/emptyUser.png"
                                        }
                                        alt="My Team"
                                        className={classes.userImg}
                                        // onError={(e: any) => {
                                        //   e.target.src = EmptyUser; // some replacement image
                                        // }}
                                      />
                                    </TableCell>
                                  );
                                } else if (item?.isButton) {
                                  return (
                                    <TableCell className="p-0">
                                      {data?.isSafe ? (
                                        <CheckCircleIcon color="primary" />
                                      ) : (
                                        <HighlightOffSharpIcon
                                          onClick={() =>
                                            handleIsSafe(data.authId)
                                          }
                                          className="icon-danger cursor-pointer"
                                        />
                                      )}
                                    </TableCell>
                                  );
                                } else if (item?.isAuthType) {
                                  return (
                                    <TableCell className="p-0">
                                      {data?.wokplaceData?.isEmergency
                                        ? "Assembly Point"
                                        : data?.authType}
                                    </TableCell>
                                  );
                                } else {
                                  let array = [];
                                  if (item.key.indexOf(".") > -1) {
                                    array = item.key.split(".");
                                    return (
                                      <TableCell>
                                        {data &&
                                        data[array[0]][array[1]] !== undefined
                                          ? data[array[0]][array[1]]
                                          : item.key}
                                      </TableCell>
                                    );
                                  }
                                  // data["time"] = data["time"]
                                  //   ? helperFunc.commonDateFormat(
                                  //       data["time"],
                                  //       "HH:mm:ss"
                                  //     )
                                  //   : "N/A";
                                  //   data["Time"] = data["Time"]
                                  //   ? helperFunc.commonDateFormat(
                                  //       data["Time"],
                                  //       "HH:mm:ss"
                                  //     )
                                  //   : "N/A";
                                  // data["Type"] =
                                  //   approvalStatusEmployeeType[
                                  //     data["employeeType"]
                                  //   ];

                                  return (
                                    <TableCell>
                                      <span>
                                        {data && data[item.key] !== undefined
                                          ? data[item.key]
                                          : item.key
                                          ? item.key
                                          : "N/A"}
                                      </span>
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={headerArr && headerArr.length}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            No Record Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={countData?.updatedCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </Fragment>
          ) : (
            <div>
              <Paper style={{ marginTop: "15px", marginRight: "15px" }}>
                <GridList
                  selectedRow={
                    countData && countData.list ? countData.list : undefined
                  }
                />
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={countData?.updatedCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmergencyList;
