import * as React from "react";
import { FC } from "react";
import {
  TextField,
  Datagrid,
  List,
  Pagination,
  downloadCSV,
  useRefresh,
  FormDataConsumer,
  required,
  TopToolbar,
  ExportButton,
  FunctionField,
  DateInput,
} from "react-admin";
import {
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Theme,
  IconButton,
  Card as MuiCard,
} from "@material-ui/core";
import helperFunc from "../helperFuncs";
import { ListComponentProps, Company } from "../types";
import jsonExport from "jsonexport/dist";
import { Button, Box } from "@material-ui/core";
import { Form } from "react-final-form";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import moment from "moment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import EmployeeSelectInput from "../components/EmployeeSelectInput";
import authProvider from "../authProvider";

const genderName: any = {
  m: "Male",
  f: "Female",
  o: "Other",
};

const PostFilterForm = (data: any, props: any) => {
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      if (values.fromDate) {
        values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      }
      if (values.toDate) {
        values.toDate = moment(values.toDate).format("YYYY-MM-DD");
      }
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    } else {
      data.setFilters(values);
      data.handleDrawerClose("fromFilter");
    }
  };

  const resetFilter = () => {
    data.setFilters(
      {
        fromDate: helperFunc.startOfMonth(0),
        toDate: helperFunc.addDays(0, "forReport"),
        company: helperFunc.selectedCompany(),
      },
      []
    );
    data.handleDrawerClose("fromFilter");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    company_id: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className="filter-main">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={data.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => data.handleDrawerClose("fromFilter")}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div style={{ marginLeft: 15 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={data.filterValues}
            validate={(values) => {
              const errors: any = {};

              if (
                helperFunc.getUserData() &&
                helperFunc.getUserData().type === "super" &&
                !values.company
              ) {
                errors.company = "Required";
              }
              if (values.toDate && new Date(values.toDate) > new Date()) {
                errors.toDate = "To Date must be smaller than current Date";
              }

              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    {helperFunc.getUserData().type === "super" ? (
                      <FormDataConsumer alwaysOn>
                        {({ formData }: { formData: any }) => (
                          <CompanyReferenceInput
                            helperText={false}
                          ></CompanyReferenceInput>
                        )}
                      </FormDataConsumer>
                    ) : null}
                  </Box>
                </Box>

                {helperFunc.getChildCompany() === "true" ? (
                  <>
                    {" "}
                    <Box display="flex" alignItems="flex-start">
                      <Box component="span" mr={2}>
                        <FormDataConsumer formClassName={classes.root} alwaysOn>
                          {({ formData }: { formData: any }) => (
                            <ChildCompanySelectInput
                              helperText={false}
                              filter={{
                                isActive: true,
                                company: formData.company,
                              }}
                            />
                          )}
                        </FormDataConsumer>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-start">
                      <Box component="span" mr={2}>
                        <FormDataConsumer formClassName={classes.root} alwaysOn>
                          {({ formData }: { formData: any }) => (
                            <EmployeeSelectInput
                              helperText={false}
                              filter={{
                                isActive: true,
                                company: formData.company,
                                childCompanyId: formData.childCompanyId,
                              }}
                              source="invitedEmployeeId"
                            />
                          )}
                        </FormDataConsumer>
                      </Box>
                    </Box>{" "}
                  </>
                ) : (
                  ""
                )}

                <Box display="flex" alignItems="flex-start">
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root}>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          validate={[required()]}
                          source="fromDate"
                          label="label.fromDate"
                          alwaysOn
                          helperText={false}
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" mb={1}>
                  <Box component="span" mr={2}>
                    <FormDataConsumer formClassName={classes.root} alwaysOn>
                      {({ formData }: { formData: any }) => (
                        <DateInput
                          options={{
                            format: helperFunc.datePickerFormat(),
                            clearable: true,
                          }}
                          validate={[required()]}
                          source="toDate"
                          label="label.toDate"
                          alwaysOn
                          helperText={false}
                          inputProps={{
                            min: "1900-08-10",
                            max: "3000-08-20",
                          }}
                        />
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <div className="actionbutton">
                  <Button variant="outlined" color="primary" type="submit">
                    Apply
                  </Button>
                  <Button variant="outlined" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

const ListActions = (props: any) => {
  const {
    total,
    resource,
    currentSort,
    filterValues,
    exporter,
    handleDrawerOpen,
  } = props;
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar>
      <Button
        style={{
          padding: "3px 0px 0px 0px",
          fontSize: "13px",
        }}
        color="primary"
        component="span"
        onClick={() => handleDrawerOpen()}
      >
        <FilterListIcon style={{ marginRight: "0.2em" }} />
        {isSmall ? null : "Filter"}
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};
const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  root: {},
  employee: {
    minWidth: "150px",
  },
  name: { display: "inline-block" },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: "0.5em",
  },
  actionSpacer: {
    display: "flex",
    justifyContent: "space-around",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

const exporter = (records: any) => {
  const recordsForExport = records.map((record: any) => {
    const mode: any = {
      mobile: "Mobile",
      edge: "Edge",
      manual: "Manual",
      cron: "Cron",
      auto: "Auto",
    };
    const {
      company,
      employee,
      _id,
      id,
      invitedEmployeeId,
      childCompanyId,
      isApprovedRequired,
      isApproved,
      isActive,
      isDeleted,
      fromDate,
      toDate,
      createdBy,
      __v,
      createdAt,
      updatedAt,
      totalPerson,
      from,
      purpose,
      vehicleType,
      vehicleNumber,
      remarks,
      updatedBy,
      ...dataToExport
    } = record; // omit fields
    dataToExport["Subsidiary"] = childCompanyId && childCompanyId.name;
    dataToExport["Meeting With"] = invitedEmployeeId && invitedEmployeeId.name;
    dataToExport["Name"] = employee && employee.name;
    dataToExport["Mobile"] = employee && employee.phone;
    dataToExport["From"] = employee && employee.from;
    dataToExport["Total Person"] = totalPerson;
    dataToExport["Gender"] = employee?.gender && genderName[employee.gender];

    dataToExport["From Date"] = record.fromDate
      ? helperFunc.commonDateFormat(record.fromDate, "HH:mm:ss")
      : "N/A";
    dataToExport["To Date"] = record.toDate
      ? helperFunc.commonDateFormat(record.toDate, "HH:mm:ss")
      : "N/A";
    dataToExport["Purpose"] = purpose;

    return dataToExport;
  });
  jsonExport(
    recordsForExport,
    {
      headers: [], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Visitor Report"); // download as 'xxxx.csv` file
    }
  );
};

const InviteVisitorReport: FC<ListComponentProps<{ id: string }>> = (
  props: any
) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const refresh = useRefresh();
  const theme = useTheme();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (check: any) => {
    if (open === true && check === "fromlist") {
      setOpen(false);
    } else if (open === true && check === "fromFilter") {
      setOpen(false);
    }
  };

  const filterData = () => {
    const user = helperFunc.getUserData();
    let myFilters: any = {};
    myFilters["fromDate"] = helperFunc.startOfMonth(0);
    myFilters["toDate"] = helperFunc.addDays(0, "forReport");
    if (user.type === "super") {
      myFilters["company"] = helperFunc.selectedCompany();
    }
    return myFilters;
  };

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div className={classes.root}>
      <>
        {isXSmall ? (
          <h2
            style={{
              height: 40,
              fontSize: 30,
              backgroundColor: "#fff",
              padding: 10,
            }}
          >
            Attendance Report
          </h2>
        ) : null}
        <div
          className={open === true ? "overlay" : ""}
          onClick={() => handleDrawerClose("fromlist")}
        />

        <div
          style={
            isXSmall
              ? {
                  width: window.screen.width,
                  overflowX: "scroll",
                }
              : {}
          }
        >
          <List
            className="list-view2"
            bulkActionButtons={false}
            title="Invite Visitor Report"
            exporter={exporter}
            actions={<ListActions handleDrawerOpen={handleDrawerOpen} />}
            {...props}
            filters={
              <PostFilterForm
                {...props}
                handleDrawerClose={handleDrawerClose}
                open={open}
              />
            }
            filterDefaultValues={filterData()}
            perPage={20}
            pagination={
              <Pagination
                labelRowsPerPage="Records per page"
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            }
            sort={{ field: "name", order: "ASC" }}
          >
            <Datagrid>
              <TextField
                source="company.name"
                label="Subsidiary"
                // sortable={false}
                sortBy="company.name"
              />

              <TextField
                source="employee.name"
                label="Visitor Name"
                // sortable={false}
                sortBy="employee.name"
              />
              <TextField
                source="invitedEmployeeId.name"
                label="Meeting With"
                sortBy="invitedEmployeeId.name"
              />
              <TextField source="employee.phone" label="Mobile" />
              <TextField
                source="employee.from"
                label="Company"
                // sortable={false}
              />
              <TextField
                source="totalPerson"
                label="Total Person"
                // sortable={false}
              />
              {!authProvider?.isHiddenField(
                props?.location?.pathname,
                "gender",
                "isHide"
              ) && (
                <FunctionField
                  label="Gender"
                  sortBy="employee.gender"
                  render={(record: any) =>
                    `${
                      record?.employee?.gender
                        ? genderName[record.employee.gender]
                        : "N/A"
                    }  `
                  }
                />
              )}

              <FunctionField
                label="From Date"
                sortBy="fromDate"
                render={(record: any) =>
                  `${
                    record.fromDate
                      ? helperFunc.commonDateFormat(record.fromDate, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />

              <FunctionField
                label="To Date"
                sortBy="toDate"
                render={(record: any) =>
                  `${
                    record.toDate
                      ? helperFunc.commonDateFormat(record.toDate, "HH:mm:ss")
                      : "N/A"
                  }  `
                }
              />

              <TextField source="purpose" label="Purpose" sortable={false} />
            </Datagrid>
          </List>
        </div>
      </>
    </div>
  );
};

export default InviteVisitorReport;
