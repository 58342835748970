import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextInput,
  FormDataConsumer,
  required,
  useDataProvider,
  Button as ReactButton,
  maxLength,
  regex,
  number,
  useTranslate,
} from "react-admin";
import { Theme, useMediaQuery, Button } from "@material-ui/core";
import { streamStyle } from "../helperStyle";
import {
  mobValidate,
  nameAlphaValidate,
  validate,
  validateEmail,
} from "../helperValidate";
import { styleData } from "../helperConst";
import { gender, vehicleType } from "../helperChoice";
import { CaptureDialogueBox } from "../inviteVisitors/captureDialogueBox";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { toastError, toastInfo } from "../components/toast";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "./themes";
import { Form } from "react-final-form";
import { VerifyDialogueBox } from "../inviteVisitors/verifyDialogueBox";
import { Loader } from "semantic-ui-react";
import { SelectInput } from "../common";
import { sercoLogo, turnoutLogoWhite } from "../img";

const useStyles = makeStyles(streamStyle);

const CreateUserPublic = (props: any) => {
  const [imgSrc, setImgSrc] = useState() as any;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openCaptureDialogue, setOpenCaptureDialogue] = useState(false);
  const [verifyDialogueOpen, setVerifyDialogueOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { id } = useParams() as any;
  const translate = useTranslate();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isSerco = process.env.REACT_APP_DATA_PROVIDER_IS_SERCO;

  const classes = useStyles();
  const dataProvider = useDataProvider();

  const saveForm = (value: any) => {
    setButtonLoading(true);
    const resourcePath = `public/create-employee`;

    dataProvider
      .callCustom(resourcePath, { ...value, company: id })
      .then((response: any) => {
        setVerifyDialogueOpen(true);
        setButtonLoading(false);
        toastInfo("Employee Created Mail Sent Successfully");
        value = "";
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  const handleOpenCaptureDialogue = () => {
    setOpenCaptureDialogue(true);
  };

  const closeVerifyDialogueBox = () => {
    window.location.reload();
    setVerifyDialogueOpen(false);
  };

  const nameValidate = [
    required(),
    maxLength(50),
    regex(/.*\S.*/, "Only whitespaces are not allowed"),
  ];

  const codeValidate = [
    required(),
    maxLength(20),
    regex(/.*\S.*/, "Only whitespaces are not allowed"),
  ];

  const altmobValidate = [number()];

  const addressValidate = [
    required(),
    maxLength(200),
    regex(/.*\S.*/, "Only whitespaces are not allowed"),
  ];

  return (
    <>
      {isLoad ? (
        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <CaptureDialogueBox
            open={openCaptureDialogue}
            setOpenCaptureDialogue={setOpenCaptureDialogue}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
          />

          <div
            className={`d-flex w-100 visitor-page ${
              isSmall || isXsmall ? "" : "img-form"
            }`}
          >
            <Form {...props} onSubmit={() => console.log("a")} toolbar={null}>
              {(props: any) => (
                <form>
                  <div
                    className={`text-center ${
                      isSmall || isXsmall
                        ? "visitor-heading-sm"
                        : "visitor-heading"
                    }`}
                  >
                    <div>
                      <img
                        // src={uploadApiUrl + visitorData?.item?.company_logo}
                        src={isSerco === "true" ? sercoLogo : turnoutLogoWhite}
                        alt="logo"
                        className="img-fluid turnout-logo"
                        style={{ width: "50px" }}
                      ></img>
                    </div>
                    <div style={{ marginLeft: "40px" }}>
                      <h5 key={Math.random()}>Employee Create</h5>
                    </div>

                    <i
                      className="fa fa-home"
                      onClick={() => {
                        window.location.replace("/login");
                      }}
                    ></i>
                  </div>

                  <div
                    className={
                      isSmall || isXsmall
                        ? classes.wmMobile
                        : classes.formWrapper
                    }
                  >
                    <div>
                      <h6 key={Math.random()}>Basic Information</h6>
                      {/* <hr /> */}
                    </div>
                    <CompanyReferenceInput></CompanyReferenceInput>

                    <div
                      className={
                        isSmall || isXsmall
                          ? classes.inlineDiv
                          : classes.flexDiv
                      }
                    >
                      <FormDataConsumer
                        // className="test"
                        formClassName={
                          isXsmall ? classes.name : classes.customClass
                        }
                      >
                        {({ formData }: { formData: any }) => (
                          <TextInput
                            autoFocus
                            source="name"
                            formClassName={classes.name}
                            validate={nameAlphaValidate}
                            label="Employee Name"
                          />
                        )}
                      </FormDataConsumer>
                      <div
                        className={
                          isXsmall || isSmall ? classes.name : classes.ml32
                        }
                      >
                        <FormDataConsumer
                          formClassName={
                            isXsmall || isSmall ? classes.inline : classes.code
                          }
                        >
                          {({ formData }: { formData: any }) => (
                            <TextInput
                              label="Employee Code"
                              source="employeeCode"
                              formClassName={isXsmall ? null : classes.code}
                              validate={codeValidate}
                              autoComplete="off"
                            />
                          )}
                        </FormDataConsumer>
                      </div>
                    </div>

                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <TextInput
                        label="label.mobile"
                        source="phone"
                        formClassName={classes.code}
                        validate={mobValidate}
                      />
                      <div style={isSmall || isXsmall ? undefined : styleData}>
                        <TextInput
                          label="Alternate Mobile No."
                          source="alternatePhone"
                          formClassName={classes.code}
                          validate={altmobValidate}
                        />
                      </div>
                    </div>
                    <TextInput
                      source="email"
                      label="Email"
                      className={isSmall || isXsmall ? "" : classes.semiWidth}
                      validate={validateEmail}
                    />

                    <div>
                      <h6>Other Details</h6>
                      {/* <hr /> */}
                    </div>

                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <FormDataConsumer
                        formClassName={isXsmall ? null : classes.customClass}
                      >
                        {({ formData }: { formData: any }) => (
                          <FormDataConsumer
                            // className="test"
                            formClassName={
                              isXsmall ? classes.name : classes.customClass
                            }
                          >
                            {({ formData }: { formData: any }) => (
                              <SelectInput
                                filter={{
                                  isActive: true,
                                  company: id,
                                }}
                                label="Company (Subsidiary)"
                                source="childCompanyId"
                                apiName="public/search/companyMasters"
                                enabled={true}
                              />
                            )}
                          </FormDataConsumer>
                        )}
                      </FormDataConsumer>
                      <div style={isSmall || isXsmall ? undefined : styleData}>
                        {" "}
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.name}
                        >
                          {({ formData }: { formData: any }) => {
                            return (
                              <SelectInput
                                filter={{
                                  isActive: true,
                                  company: id,
                                }}
                                label="Departments"
                                source="department"
                                apiName="public/search/departments"
                                enabled={true}
                              />
                            );
                          }}
                        </FormDataConsumer>
                      </div>
                    </div>

                    <div>
                      <h6 key={Math.random()}>Employee Rights</h6>
                      {/* <hr /> */}
                    </div>

                    <div
                      className={
                        isSmall || isXsmall
                          ? classes.inlineDiv
                          : classes.flexDiv
                      }
                    >
                      <FormDataConsumer
                        formClassName={
                          isXsmall ? classes.name : classes.customClass
                        }
                      >
                        {({ formData }: { formData: any }) => (
                          <SelectInput
                            filter={{
                              isActive: true,
                              company: id,
                            }}
                            label="Designations"
                            source="designation"
                            apiName="public/search/designations"
                            enabled={true}
                          />
                        )}
                      </FormDataConsumer>
                      <div
                        className={
                          isXsmall || isSmall ? classes.name : classes.ml32
                        }
                      >
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.code}
                        >
                          {({ formData }: { formData: any }) => {
                            return (
                              <SelectInput
                                filter={{
                                  isActive: true,
                                  company: id,
                                  designation: formData.designation,
                                }}
                                label="Reporting Manager"
                                source="reportingManager"
                                apiName="public/search/reporting-manager-employee"
                                enabled={formData.designation !== undefined}
                              />
                            );
                          }}
                        </FormDataConsumer>
                        {/* ) : null} */}
                      </div>
                    </div>

                    <div>
                      <h6 key={Math.random()}>Employee Location</h6>
                      {/* <hr /> */}
                    </div>

                    <div
                      className={
                        isSmall || isXsmall
                          ? classes.inlineDiv
                          : classes.flexDiv
                      }
                    >
                      <FormDataConsumer
                        formClassName={
                          isXsmall ? classes.name : classes.customClass
                        }
                      >
                        {({ formData }: { formData: any }) => (
                          <FormDataConsumer
                            formClassName={isXsmall ? null : classes.name}
                          >
                            {({ formData }: { formData: any }) => (
                              <SelectInput
                                filter={{
                                  isActive: true,
                                  company: id,
                                }}
                                label="Zone"
                                source="zoneId"
                                apiName="public/search/zones"
                                enabled={true}
                                isValidate={false}
                                initialValue={true}
                              />
                            )}
                          </FormDataConsumer>
                        )}
                      </FormDataConsumer>
                      <div
                        className={
                          isXsmall || isSmall ? classes.name : classes.ml32
                        }
                      >
                        <FormDataConsumer
                          formClassName={
                            isXsmall || isSmall ? null : classes.code
                          }
                        >
                          {({ formData }: { formData: any }) => (
                            <SelectInput
                              filter={{
                                isActive: true,
                                company: id,
                                zoneId: formData.zoneId,
                              }}
                              label="Region"
                              source="regionId"
                              apiName="public/search/regions"
                              enabled={true}
                              isValidate={false}
                              initialValue={true}
                            />
                          )}
                        </FormDataConsumer>
                      </div>
                    </div>

                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <FormDataConsumer
                        formClassName={isXsmall ? null : classes.code}
                      >
                        {({ formData }: { formData: any }) => (
                          <SelectInput
                            filter={{
                              isActive: true,
                              company: id,
                              regionId: formData.regionId,
                            }}
                            label="State"
                            source="state"
                            apiName="public/search/states"
                            enabled={true}
                            isValidate={false}
                            initialValue={true}
                          />
                        )}
                      </FormDataConsumer>
                      <div
                        className={
                          isXsmall || isSmall ? classes.name : classes.ml32
                        }
                      >
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.name}
                        >
                          {({ formData }: { formData: any }) => (
                            <SelectInput
                              filter={{
                                isActive: true,
                                company: id,
                                state: formData.state,
                              }}
                              label="City"
                              source="city"
                              apiName="public/search/cities"
                              enabled={true}
                              isValidate={false}
                              initialValue={true}
                            />
                          )}
                        </FormDataConsumer>
                      </div>
                    </div>
                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <FormDataConsumer
                        formClassName={isXsmall ? null : classes.code}
                      >
                        {({ formData }: { formData: any }) => (
                          <SelectInput
                            filter={{
                              isActive: true,
                              company: id,
                              city: formData.city,
                            }}
                            label="Branch"
                            source="branchId"
                            apiName="public/search/branches"
                            enabled={true}
                            isValidate={false}
                            initialValue={true}
                          />
                        )}
                      </FormDataConsumer>
                      <div
                        className={
                          isXsmall || isSmall ? classes.name : classes.ml32
                        }
                      >
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.name}
                        >
                          {({ formData }: { formData: any }) => (
                            <TextInput
                              fullWidth={isXsmall ? null : true}
                              label="label.address"
                              source="metaInfo.address.lines"
                              formClassName={classes.address}
                              validate={addressValidate}
                            />
                          )}
                        </FormDataConsumer>
                      </div>
                    </div>

                    <div
                      className={
                        isSmall || isXsmall
                          ? classes.inlineDiv
                          : classes.flexDiv
                      }
                    >
                      <FormDataConsumer
                        formClassName={
                          isXsmall ? classes.name : classes.customClass
                        }
                      >
                        {({ formData }: { formData: any }) => <div></div>}
                      </FormDataConsumer>
                    </div>
                  </div>

                  <div className="form-wrapper d-flex justify-content-center">
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => saveForm(formData)}
                          className={classes.button}
                        >
                          Submit
                        </Button>
                      )}
                    </FormDataConsumer>
                  </div>
                </form>
              )}
            </Form>
            <VerifyDialogueBox
              open={verifyDialogueOpen}
              message={translate("resources.createSelfUser.message")}
              close={closeVerifyDialogueBox}
            />
          </div>
        </>
      )}
    </>
  );
};

const CreateUserPublicWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <CreateUserPublic {...props} />
  </ThemeProvider>
);

export default CreateUserPublicWithTheme;
