import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  TextInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  required,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  useDataProvider,
  Button as ReactButton,
  useTranslate,
} from "react-admin";
import {
  Theme,
  useMediaQuery,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { streamStyle } from "../helperStyle";
import {
  mobValidate,
  nameAlphaValidate,
  validate,
  validateEmail,
} from "../helperValidate";
import { styleData } from "../helperConst";
import { gender, vehicleType } from "../helperChoice";
import { CaptureDialogueBox } from "../inviteVisitors/captureDialogueBox";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { toastError, toastInfo } from "../components/toast";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "./themes";
import { Form } from "react-final-form";
import ChildCompanyPublicSelectInput from "../components/ChildCompanyPublicSelectInput";
import { useLocation } from "react-router-dom";
import { VerifyDialogueBox } from "../inviteVisitors/verifyDialogueBox";
import authProvider from "../authProvider";
import { Loader } from "semantic-ui-react";
import { TermsAndCondition } from "./components/TermsAndCondition";
const useStyles = makeStyles(streamStyle);

const InviteVisitorPublic = (props: any) => {
  const [childCompanyChange, setChildCompanyChange] = useState(Boolean);
  const [imgSrc, setImgSrc] = useState() as any;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openCaptureDialogue, setOpenCaptureDialogue] = useState(false);
  const [verifyDialogueOpen, setVerifyDialogueOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [visitorData, setVisitorData] = useState() as any;

  const { id } = useParams() as any;
  const fromDate = new Date();

  const toDate = moment(new Date());
  toDate.add(3, "h");

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const uploadApiUrl = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;
  const location = useLocation();
  const childCompanyData = (data: any) => {
    setChildCompanyChange(true);
    setTimeout(function () {
      setChildCompanyChange(false);
    }, 1000);
  };

  useEffect(() => {
    setIsLoad(true);

    const resourcePath = `public/visitor/get/${id}`;
    dataProvider
      .callCustom(resourcePath, {})
      .then((response: any) => {
        setIsLoad(false);
        setVisitorData(response?.data);
      })
      .catch((err: any) => {
        toastError(err?.message);
      });
  }, []);

  const saveForm = (value: any) => {
    let payLoad;
    if (
      visitorData?.company?.customConfiguration &&
      authProvider?.isHiddenField(
        location?.pathname,
        "isApprovedRequired",
        "defaultValue",
        visitorData
      ) === true
    ) {
      payLoad = {
        ...value,
        invitedEmployeeId: visitorData?.invitedEmployeeId?._id,
        isApprove: true,
        imageSrc: imgSrc,
      };
    } else
      payLoad = {
        ...value,
        invitedEmployeeId: visitorData?.invitedEmployeeId?._id,
        imageSrc: imgSrc,
      };

    setButtonLoading(true);
    const resourcePath = `public/visitor/create/${id}`;

    dataProvider
      .callCustom(resourcePath, payLoad)
      .then((response: any) => {
        setVerifyDialogueOpen(true);
        setButtonLoading(false);
        toastInfo("Visitor Request Sent Successfully");
        value = "";
      })
      .catch((err: any) => {
        toastError(err.message);
      });
  };

  const handleOpenCaptureDialogue = () => {
    setOpenCaptureDialogue(true);
  };

  const closeVerifyDialogueBox = () => {
    window.location.reload();
    setVerifyDialogueOpen(false);
  };

  return (
    <>
      {isLoad ? (
        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <CaptureDialogueBox
            open={openCaptureDialogue}
            setOpenCaptureDialogue={setOpenCaptureDialogue}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
          />

          <div
            className={`d-flex w-100 visitor-page ${
              isSmall || isXsmall ? "" : "img-form"
            }`}
          >
            <Form {...props} onSubmit={() => console.log("a")} toolbar={null}>
              {(props: any) => (
                <form>
                  <div
                    className={`text-center ${
                      isSmall || isXsmall
                        ? "visitor-heading-sm"
                        : "visitor-heading"
                    }`}
                  >
                    <img
                      src={uploadApiUrl + visitorData?.item?.company_logo}
                      // src="assets/img/Turnout-logo-white.png"
                      alt="logo"
                      className="img-fluid turnout-logo"
                      style={{ width: "100px" }}
                    ></img>
                    <h5 key={Math.random()}>Visitor Request</h5>
                    <i
                      className="fa fa-home"
                      onClick={() => {
                        window.location.replace("/login");
                      }}
                    ></i>
                  </div>
                  {/* <div className="col-12">
              <div
                className="upload-photo-box"
                onClick={handleOpenCaptureDialogue}
              >
                <i className="fa fa-camera mr-2"></i>
                Capture Photo
              </div>
            </div> */}

                  <div
                    className={
                      isSmall || isXsmall
                        ? classes.wmMobile
                        : classes.formWrapper
                    }
                  >
                    <div>
                      <h6 key={Math.random()}>Visitor Details</h6>
                      {/* <hr /> */}
                    </div>
                    <CompanyReferenceInput></CompanyReferenceInput>

                    <div
                      className={
                        isSmall || isXsmall
                          ? classes.inlineDiv
                          : classes.flexDiv
                      }
                    >
                      <FormDataConsumer
                        // className="test"
                        formClassName={
                          isXsmall ? classes.name : classes.customClass
                        }
                      >
                        {({ formData }: { formData: any }) => (
                          <ChildCompanyPublicSelectInput
                            filter={{
                              isActive: true,
                              company: id,
                            }}
                            validate={[required()]}
                            dataManage={childCompanyData}
                            paramsId={id}
                            defaultValue={visitorData?.item?.childCompanyId}
                          ></ChildCompanyPublicSelectInput>
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer
                        formClassName={
                          isXsmall || isSmall ? classes.inline : classes.code
                        }
                      >
                        {({ formData }: { formData: any }) => (
                          <TextInput
                            source="invitedEmployeeId"
                            label="Meeting With"
                            className={isXsmall ? null : classes.ml32}
                            validate={nameAlphaValidate}
                            disabled={true}
                            defaultValue={visitorData?.invitedEmployeeId?.name}
                          />
                          // <SearchableEmployeePublicSelectInput
                          //   source="invitedEmployeeId"
                          //   label="Meeting With"
                          //   filter={{
                          //     isActive: true,
                          //     childCompanyId: formData.childCompanyId,
                          //   }}
                          //   // childCompanyChange={childCompanyChange}
                          //   validate={[required()]}
                          //   className={isXsmall ? null : classes.ml32}
                          //   paramsId={id}
                          //   defaultValue={visitorData?.item?.invitedEmployeeId}
                          // ></SearchableEmployeePublicSelectInput>
                        )}
                      </FormDataConsumer>
                    </div>

                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexDiv}
                    >
                      <TextInput
                        source="name"
                        label="Name"
                        formClassName={classes.name}
                        validate={nameAlphaValidate}
                        helperText={false}
                        defaultValue={visitorData?.item?.name}
                      />
                      {visitorData?.company?.customConfiguration &&
                        authProvider?.isHiddenField(
                          location?.pathname,
                          "gender",
                          "isHide",
                          visitorData
                        ) === false && (
                          <div
                            style={isSmall || isXsmall ? undefined : styleData}
                          >
                            <RadioButtonGroupInput
                              source="gender"
                              formClassName={isXsmall ? null : classes.code}
                              fullWidth
                              choices={gender}
                              helperText={false}
                              validate={validate}
                              style={{ width: "280px" }}
                              defaultValue={visitorData?.item?.gender}
                            />
                          </div>
                        )}
                    </div>
                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <TextInput
                        label="label.mobile"
                        source="phone"
                        formClassName={classes.code}
                        validate={mobValidate}
                        defaultValue={visitorData?.item?.phone}
                      />
                      <div style={isSmall || isXsmall ? undefined : styleData}>
                        <TextInput
                          label="Company Email Address"
                          source="email"
                          formClassName={classes.code}
                          validate={validateEmail}
                          defaultValue={visitorData?.item?.email}
                        />
                      </div>
                    </div>
                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <TextInput
                        label="Company"
                        source="from"
                        formClassName={classes.name}
                        defaultValue={visitorData?.item?.from}
                        validate={[required()]}
                      />

                      <div style={isSmall || isXsmall ? undefined : styleData}>
                        <NumberInput
                          source="totalPerson"
                          label="Total No Of Person"
                          formClassName={classes.code}
                          validate={validate}
                          helperText={false}
                          defaultValue={visitorData?.item?.totalPerson}
                        />
                      </div>
                    </div>
                    {visitorData?.company?.customConfiguration &&
                    authProvider?.isHiddenField(
                      location?.pathname,
                      "vehicleType",
                      "isHide",
                      visitorData
                    ) === true ? (
                      <>
                        <BooleanInput
                          source="isWithVehicle"
                          label="Is Carry Vehicle"
                          defaultValue={false}
                        />
                        {/* <div style={isSmall || isXsmall ? undefined : styleData}> */}
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.code}
                        >
                          {({ formData }: { formData: any }) =>
                            formData?.isWithVehicle === true ? (
                              <TextInput
                                label="License Number"
                                source="vehicleNumber"
                                formClassName={classes.code}
                                validate={validate}
                                // defaultValue={visitorData?.item?.vehicleNumber}
                              />
                            ) : (
                              ""
                            )
                          }
                        </FormDataConsumer>
                        {/* </div> */}
                      </>
                    ) : (
                      <div
                        className={
                          isSmall ? classes.inlineDiv : classes.flexCol
                        }
                      >
                        <FormDataConsumer
                          formClassName={isXsmall ? null : classes.code}
                        >
                          {({ formData }: { formData: any }) => (
                            <RadioButtonGroupInput
                              source="vehicleType"
                              choices={vehicleType}
                              helperText={false}
                              defaultValue={visitorData?.item?.vehicleType}
                              // validate={validate}
                              style={{ width: "290px" }}
                            />
                          )}
                        </FormDataConsumer>

                        <div
                          style={isSmall || isXsmall ? undefined : styleData}
                        >
                          <FormDataConsumer
                            formClassName={isXsmall ? null : classes.code}
                          >
                            {({ formData }: { formData: any }) =>
                              formData?.vehicleType === "fourWheeler" ||
                              formData?.vehicleType === "twoWheeler" ? (
                                <TextInput
                                  label="Vehicle Number"
                                  source="vehicleNumber"
                                  formClassName={classes.code}
                                  validate={validate}
                                  defaultValue={
                                    visitorData?.item?.vehicleNumber
                                  }
                                />
                              ) : (
                                ""
                              )
                            }
                          </FormDataConsumer>
                        </div>
                      </div>
                    )}

                    <div>
                      <h6>Entry Validity Duration</h6>
                      <hr />
                    </div>

                    <div
                      className={isSmall ? classes.inlineDiv : classes.flexCol}
                    >
                      <FormDataConsumer
                        formClassName={isXsmall ? null : classes.customClass}
                      >
                        {({ formData }: { formData: any }) => (
                          <DateTimeInput
                            source="fromDate"
                            resource="visitor-qr-code"
                            formClassName={classes.customClass}
                            validate={validate}
                            defaultValue={fromDate}
                          />
                        )}
                      </FormDataConsumer>
                      <div style={isSmall || isXsmall ? undefined : styleData}>
                        <FormDataConsumer>
                          {({ formData }: { formData: any }) => (
                            <DateTimeInput
                              source="toDate"
                              resource="visitor-qr-code"
                              defaultValue={toDate}
                              // className={classes.employee}
                              validate={validate}
                            />
                          )}
                        </FormDataConsumer>
                      </div>
                    </div>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <TextInput
                          source="purpose"
                          resource="visitor-qr-code"
                          label="Purpose"
                          className={
                            isSmall || isXsmall ? "" : classes.semiWidth
                          }
                          defaultValue={visitorData?.item?.purpose}
                          validate={[required()]}
                        />
                      )}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <TextInput
                          source="remarks"
                          resource="visitor-qr-code"
                          label="Remarks"
                          className={
                            isSmall || isXsmall ? "" : classes.semiWidth
                          }
                          defaultValue={visitorData?.item?.remarks}
                        />
                      )}
                    </FormDataConsumer>

                    <BooleanInput
                      source="termsAndConditions"
                      label="Terms and Condition"
                      validate={[required()]}
                      defaultValue={true}
                      helperText={false}
                    />
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) =>
                        formData?.termsAndConditions && <TermsAndCondition />
                      }
                    </FormDataConsumer>
                  </div>

                  <div className="form-wrapper d-flex justify-content-center">
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) =>
                        !buttonLoading ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => saveForm(formData)}
                            className={classes.button}
                          >
                            Submit
                          </Button>
                        ) : (
                          <LinearProgress />
                        )
                      }
                    </FormDataConsumer>
                  </div>
                </form>
              )}
            </Form>
            <VerifyDialogueBox
              open={verifyDialogueOpen}
              close={closeVerifyDialogueBox}
              message={translate(
                "resources.inviteVisitors.visitor_create_message"
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

const InviteVisitorPublicWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <InviteVisitorPublic {...props} />
  </ThemeProvider>
);

export default InviteVisitorPublicWithTheme;
