import React, { useState, FC } from "react";
import moment from "moment";
import {
  Button,
  SimpleForm,
  useDataProvider,
  FormDataConsumer,
  TextInput,
  DateTimeInput,
  BooleanInput,
  useRefresh,
} from "react-admin";
import {
  useMediaQuery,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps } from "../types";
import IconCancel from "@material-ui/icons/Cancel";

import { toastError, toastInfo } from "../components/toast";

import SearchableEmployeeSelectInput from "../components/SearchableEmployeeSelectInput";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
const useStyles = makeStyles({
  icon: { paddingRight: "0.5em" },
  root: { textAlign: "right" },
  isSmallRoot: { textAlign: "center", margin: 5 },
  name: { display: "inline-block" },
  company: { display: "inline-block" },
  isSmallCompany: { minWidth: 150 },
  employee: { display: "inline-block", marginLeft: 32 },
  link: {
    display: "inline-flex",
    alignItems: "center",
  },
  suggestions: {
    display: "inline-block",
    zIndex: 10000,
  },
  noOfAllotments: { display: "flex", cursor: "pointer", color: "#3f51b5" },
  allotmentMainBtn: { marginLeft: 15 },
  allotmentBtn: { display: "inline-block", marginBottom: -40 },
  removeAllotmentBtn: {
    fontSize: 12,
    border: "1px solid red",
    color: "red",
    fontWeight: 500,
    marginRight: "8px",
  },
  hidden: { visibility: "hidden" },
  tableHead: { padding: 10 },
  tableHeadCenter: { padding: 10, textAlign: "center" },
  tableValue: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  tableFlexWidth: {
    display: "flex",
    width: "350px",
    height: "65px",
    alignItems: "center",
  },
  table: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
  },
  isSmallTable: {
    minWidth: 500,
    maxWidth: "100%",
    width: "100%",
    marginTop: 15,
  },
  error: {
    zIndex: 10,
    background: "blue",
  },
  editAllotmentBtn: {
    fontSize: 12,
    border: "1px solid #3f51b5",
    color: "#3f51b5",
    fontWeight: 500,
    marginRight: "8px",
  },
  disFlexCenter: {
    display: "flex",
    alignItems: "center",
  },
});

const QRAllotment: FC<FieldProps> = ({ record, type }) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [defaultData, setDefaultData] = useState() as any;

  const fromDate = new Date();
  fromDate.setMinutes(fromDate.getMinutes() + 1);

  const newToDate = new Date();
  newToDate.setMinutes(newToDate.getMinutes() + 1);

  const toDate = moment(newToDate);
  toDate.add(3, "h");

  const handleClick = () => {
    setOpen(true);
    if (record && record.id) {
      let url = `visitor-qr-code/list`;
      dataProvider
        .callCustom(url, {
          filter: { employeeId: record?.id ? record.id : "" },
        })
        .then((response: any) => {
          if (response && response.data) {
          }
        });
    }
  };

  const handleDialogClose = () => setOpen(false);

  const addAllotment = (data: any) => {
    let url = `visitor-qr-code/create`;

    if (
      data &&
      (data.invitedEmployeeId === "" || data.invitedEmployeeId === undefined)
    ) {
      toastError("Please add Meeting with Person Name");
      return;
    } else if (
      defaultData &&
      (defaultData.purpose === "" || defaultData.purpose === undefined)
    ) {
      toastError("Please add Purpose");
      return;
    } else if (data && (data.fromDate === "" || data.fromDate === undefined)) {
      toastError("Please select From Date");
      return;
    } else if (data && new Date(data.fromDate) < new Date()) {
      toastError("From Date must be  greater than today's Date");
      return;
    } else if (data && (data.toDate === "" || data.toDate === undefined)) {
      toastError("Please select To Date");
      return;
    } else if (data.toDate && new Date(data.fromDate) > new Date(data.toDate)) {
      toastError("To Date must be  greater than from Date");
      return;
    } else {
      dataProvider
        .callCustom(url, {
          purpose: defaultData?.purpose,
          fromDate: data.fromDate,
          toDate: data.toDate,
          childCompanyId: record?.childCompanyId?._id
            ? record?.childCompanyId?._id
            : record?.childCompanyId,
          company: record?.company?._id,
          employee: record?.employee ? record.employee : "",
          invitedEmployeeId: data?.invitedEmployeeId
            ? data.invitedEmployeeId
            : "",
          isApprovedRequired: data?.isApprovedRequired
            ? data.isApprovedRequired
            : false,
          qrCodeId: record?.qrCodeId ? record?.qrCodeId : null,
        })
        .then((response: any) => {
          handleDialogClose();
          toastInfo("QR Code generated successfully");
          refresh();
        });
    }
  };

  const handleInputChange = (event: any) => {
    setDefaultData({ ...defaultData, purpose: event.target.value });
  };

  return record ? (
    <div className={classes.root}>
      {(type && type === "list") || isXsmall ? (
        <LibraryAddIcon
          className={classes.noOfAllotments}
          onClick={handleClick}
        />
      ) : null}

      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-label="Generate QR"
        style={{ zIndex: 1 }}
        className="allotment-dialog"
      >
        <DialogTitle>Generate QR for {record.name}</DialogTitle>

        <DialogContent>
          <SimpleForm validate={() => true} toolbar={null}>
            <FormDataConsumer formClassName={isXsmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <SearchableEmployeeSelectInput
                  source="invitedEmployeeId"
                  label="Meeting With*"
                  filter={{
                    isActive: true,
                    childCompanyId: formData.childCompanyId,
                  }}
                  // dataManage={dataManage}
                  defaultValue={defaultData?.invitedEmployeeId?._id}
                ></SearchableEmployeeSelectInput>
              )}
            </FormDataConsumer>
            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <TextInput
                  source="purpose"
                  label="Purpose*"
                  value={defaultData?.purpose}
                  onChange={handleInputChange}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer formClassName={isSmall ? null : classes.employee}>
              {({ formData }: { formData: any }) => (
                <DateTimeInput
                  source="fromDate"
                  resource="visitor-qr-code"
                  formClassName={classes.name}
                  defaultValue={fromDate}
                />
              )}
            </FormDataConsumer>

            <FormDataConsumer formClassName={isSmall ? null : classes.name}>
              {({ formData }: { formData: any }) => (
                <DateTimeInput
                  source="toDate"
                  resource="visitor-qr-code"
                  formClassName={classes.name}
                  defaultValue={toDate}
                />
              )}
            </FormDataConsumer>
            <BooleanInput
              source="isApprovedRequired"
              label="Approval Required"
              defaultValue={true}
              formClassName={classes.employee}
            />

            <FormDataConsumer
              formClassName={isXsmall ? null : classes.employee}
            >
              {({ formData }: { formData: any }) => (
                <MaterialButton
                  onClick={() => addAllotment(formData)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="button"
                  className={isXsmall ? undefined : classes.allotmentBtn}
                >
                  Generate
                </MaterialButton>
              )}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>

        <DialogActions>
          <Button
            label="Close"
            onClick={handleDialogClose}
            style={{ color: "#181818cf" }}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default QRAllotment;
