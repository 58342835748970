import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import {
  useDataProvider,
  FormDataConsumer,
  useNotify,
  required,
  DateInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import StateSelectInput from "../components/StateSelectInput";
import ClientMasterSelectInput from "../components/ClientMasterSelectInput";
import ClientLocationSelectInput from "../components/ClientLocationSelectInput";
import ChildCompanySelectInput from "../components/ChildCompanySelectInput";
import { Form } from "react-final-form";
import { useForm, useField } from "react-final-form-hooks";
import helperFunc from "../helperFuncs";
import CompanyReferenceInput from "../components/CompanyReferenceInput";
import { attendanceSummaryStyle } from "../helperStyle";
// import { DateInput } from "react-admin-date-inputs";
import CircularProgress from "@material-ui/core/CircularProgress";
import authProvider from "../authProvider";
import { toastError, toastInfo } from "../components/toast";
import moment from "moment";

const apiUrlUpload = process.env.REACT_APP_DATA_PROVIDER_API_URL_UPLOADS;

const AttendanceReport = (props: any): any => {
  let editResponseData: any = {};
  let list: any = [];
  const user = helperFunc.getUserData();

  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  var container: any = null;

  if (container === null) {
    container =
      typeof document !== "undefined"
        ? document.getElementById("react-admin-title")
        : null;
  }
  const useStyles = makeStyles(attendanceSummaryStyle);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  // const notify = useNotify();
  const [responseData, setResponseData] = useState({
    list: [],
    dates: [],
    holidayDates: [],
  });
  const [editResponse, setEditResponse] = useState({
    list: [],
    dates: [],
    holidayDates: [],
  });
  const [filterParams, setFilterParams] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [viewlLoading, setViewLoading] = useState<any>(null);
  const [dwnlLoading, setDwnLoading] = useState<any>(false);
  const [dwnlempLoading, setEmpDwnLoading] = useState<any>(false);
  const [generatelLoading, setGenerateLoading] = useState<any>(null);

  useEffect(() => {
    if (!isSmall && !isXSmall && container) {
      container.innerText = "";
      container.innerText = "Update Register";
    }
    return function cleanup() {
      if (!isSmall && !isXSmall && container) {
        container.innerText = "";
        container = null;
      }
    };
  }, [container]);

  const onSubmit = (value: any) => {
    setViewLoading(true);
    let params = {
      filter: {
        company: value.company || undefined,
        fromDate:
          moment.utc(moment(value.fromDate).utc()).format() || undefined,
        toDate: moment.utc(moment(value.toDate).utc()).format() || undefined,
        state: value.state || undefined,
        clientId: value.clientId || undefined,
        clientLocationId: value.clientLocationId || undefined,
        childCompanyId: value.childCompanyId || undefined,
      },
    };

    setFilterParams(params);
    dataProvider
      .callCustom("reports/monthly-attendence-report", params)
      .then((response: any) => {
        if (response) {
          setResponseData(response.data);
        }
        setViewLoading(false);
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
      });
    setEditMode(false);
  };
  const { form, handleSubmit } = useForm({ onSubmit });
  const fromDate = useField("fromDate", form);
  const toDate = useField("toDate", form);
  let currentDataIndex: number = 0;
  const incCurrentDataIndex = () => {
    currentDataIndex++;
  };
  const resetCurrentDataIndex = () => {
    currentDataIndex = 0;
  };
  const exporterDetails = () => {
    setDwnLoading(true);
    dataProvider
      .callCustom("reports/employee-summary-report", filterParams)
      .then((response: any) => {
        setDwnLoading(false);
        if (response && response.data && response.data.fileName) {
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
      });
  };
  const exporterEmployeeDetails = () => {
    setEmpDwnLoading(true);
    dataProvider
      .callCustom("reports/employee-summary-report-pivot", filterParams)
      .then((response: any) => {
        setEmpDwnLoading(false);
        if (response && response.data && response.data.fileName) {
          window.open(`${apiUrlUpload}${response.data.fileName}`, "_blank");
        }
      })
      .catch((err: any) => {
        toastError(err.message);
        // notify(err.message, "warning");
      });
  };

  const handleClick = (
    rowIndex: number,
    dateIndex: number,
    data: any,
    str: string
  ) => {
    let editdataResponse: any = { ...editResponse };
    editdataResponse.list[rowIndex].data[dateIndex][str] = data;
    setEditResponse(editdataResponse);
    setTimeout(function () {
      setFilterParams({
        ...filterParams,
        reportData: editResponse,
      });
    }, 1000);
  };

  const saveEditData = () => {
    setTimeout(function () {
      dataProvider
        .callCustom("attendence-approval/create", filterParams)
        .then((response: any) => {})
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
        });
    }, 1000);
  };

  const generateReport = () => {
    setGenerateLoading(true);
    let params = {
      ...filterParams,
      type: "approval",
    };
    setTimeout(function () {
      dataProvider
        .callCustomLocalOne("reports/attendence-approval-generate", params)
        .then((response: any) => {
          setGenerateLoading(false);
          toastInfo(
            `Report Generated successfully with this id ${response.data.attendenceId}`
          );
          // notify(
          //   `Report Generated successfully with this id ${response.data.attendenceId}`
          // );
        })
        .catch((err: any) => {
          toastError(err.message);
          // notify(err.message, "warning");
        });
    }, 1000);
  };

  return (
    <div
      style={{
        width: window.screen.width,
        overflow: isXSmall ? "scroll" : "hidden",
        height: "calc(100vh - 90px)",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      {isXSmall ? (
        <h2
          style={{
            height: 40,
            fontSize: 30,
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          Update Register
        </h2>
      ) : null}

      {/* <Paper > */}
      <div className={classes.childBlock}>
        <Form
          onSubmit={onSubmit}
          validate={(value) => {
            let difference: any;
            if (value.fromDate && value.fromDate) {
              difference = helperFunc.dateDifference(
                value.toDate,
                value.fromDate
              );
            }
            const errors: any = {};
            if (value.fromDate && new Date(value.fromDate) > new Date()) {
              errors.fromDate = "from Date must be  smaller than current Date";
            } else if (
              value.toDate &&
              new Date(value.fromDate) > new Date(value.toDate)
            ) {
              errors.toDate = "To Date must be  greater than from Date";
            } else if (value.toDate && new Date(value.toDate) > new Date()) {
              errors.toDate = "To Date must be  smaller than today's Date";
            } else if (difference > 30) {
              errors.toDate = "Dates Must be smaller then 30 Days";
            }
            return errors;
          }}
        >
          {(props: any) => (
            <form onSubmit={props.handleSubmit}>
              <Box
                component="div"
                className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
              >
                <CompanyReferenceInput></CompanyReferenceInput>
              </Box>
              <Box
                component="div"
                className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
              >
                <Box component="div">
                  <FormDataConsumer>
                    {({ formData }: { formData: any }) => (
                      <ChildCompanySelectInput
                        validate={[required()]}
                        filter={{ isActive: true, company: formData.company }}
                      ></ChildCompanySelectInput>
                    )}
                  </FormDataConsumer>
                </Box>
                <Box
                  component="div"
                  className={isXSmall ? "" : classes.mleft32}
                >
                  <FormDataConsumer>
                    {({ formData }: { formData: any }) => (
                      <StateSelectInput
                        filter={{ isActive: true, company: formData.company }}
                      ></StateSelectInput>
                    )}
                  </FormDataConsumer>
                </Box>
                {user?.isClientConfiguration && (
                  <Box
                    component="div"
                    className={isXSmall ? "" : classes.mleft32}
                  >
                    <FormDataConsumer>
                      {({ formData }: { formData: any }) => (
                        <ClientMasterSelectInput
                          // validate={[required()]}
                          filter={{
                            isActive: true,
                            company: formData.company,
                            childCompanyId: formData.childCompanyId,
                          }}
                        ></ClientMasterSelectInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                )}
              </Box>

              <Box
                component="div"
                className={isXSmall ? classes.mobileFlexCol : classes.flexCol}
              >
                {user?.isClientConfiguration && (
                  <Box component="div">
                    <FormDataConsumer
                      formClassName={isXSmall ? "" : classes.mleft32}
                    >
                      {({ formData }: { formData: any }) => {
                        return (
                          <ClientLocationSelectInput
                            // validate={[required()]}
                            filter={{
                              isActive: true,
                              company: formData.company,
                              clientId: formData.clientId,
                            }}
                          ></ClientLocationSelectInput>
                        );
                      }}
                    </FormDataConsumer>
                  </Box>
                )}
                <DateInput
                  source="fromDate"
                  label="label.fromDate"
                  alwaysOn
                  // helperText={false}
                  className={
                    isXSmall
                      ? ""
                      : user?.isClientConfiguration
                      ? classes.mleft32
                      : classes.flexCol
                  }
                  validate={[required()]}
                  options={{
                    format: helperFunc.datePickerFormat(),
                    clearable: true,
                  }}
                  inputProps={{
                    min: "1900-08-10",
                    max: "3000-08-20",
                  }}
                />
                <Box
                  component="div"
                  className={isXSmall ? "" : classes.mleft32}
                >
                  <DateInput
                    source="toDate"
                    label="label.toDate"
                    alwaysOn
                    // helperText={false}
                    // className={isXSmall ? "" : classes.mleft32}
                    validate={[required()]}
                    options={{
                      format: helperFunc.datePickerFormat(),
                      clearable: true,
                    }}
                    inputProps={{
                      min: "1900-08-10",
                      max: "3000-08-20",
                    }}
                  />
                </Box>
              </Box>

              <Box
                className={isXSmall ? classes.ButtonflexCol : classes.flexCol}
              >
                <Button
                  variant="contained"
                  className={classes.margin}
                  color="primary"
                  type="submit"
                  disabled={viewlLoading}
                  onSubmit={() => handleSubmit}
                >
                  {viewlLoading && (
                    <CircularProgress
                      size={20}
                      thickness={2}
                      style={{ color: "aliceblue" }}
                    />
                  )}
                  VIEW
                </Button>
                {/* {responseData && responseData.list && responseData.list.length > 0 ?
                                        <Button variant="contained" className={classes.margin} color="primary" type="button" onClick={() => {
                                            setEditResponse(editResponseData)
                                            resetCurrentDataIndex()
                                            setTimeout(
                                                function () {
                                                    setEditMode(true);
                                                },
                                                1000
                                            )
                                        }}>Edit</Button>
                                        : null} */}
                {authProvider.isMenuShow("updateRegister", "create") ? (
                  responseData &&
                  responseData.list &&
                  responseData.list.length > 0 ? (
                    <Button
                      variant="contained"
                      className={classes.margin}
                      color="primary"
                      type="button"
                      disabled={generatelLoading}
                      onClick={() => {
                        generateReport();
                      }}
                    >
                      {generatelLoading && (
                        <CircularProgress
                          size={20}
                          thickness={2}
                          style={{ color: "aliceblue" }}
                        />
                      )}
                      Generate Reports
                    </Button>
                  ) : null
                ) : null}
                {responseData &&
                responseData.list &&
                responseData.list.length > 0 ? (
                  <Button
                    variant="contained"
                    className={classes.margin}
                    color="primary"
                    type="button"
                    onClick={() => exporterDetails()}
                  >
                    {dwnlLoading && (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        style={{ color: "aliceblue" }}
                      />
                    )}
                    Download
                  </Button>
                ) : null}
                {responseData &&
                responseData.list &&
                responseData.list.length > 0 ? (
                  <Button
                    variant="contained"
                    className={classes.margin}
                    color="primary"
                    type="button"
                    onClick={() => exporterEmployeeDetails()}
                  >
                    {dwnlempLoading && (
                      <CircularProgress
                        size={20}
                        thickness={2}
                        style={{ color: "aliceblue" }}
                      />
                    )}
                    Employee Report Download
                  </Button>
                ) : null}
              </Box>
            </form>
          )}
        </Form>
      </div>

      {responseData.list && responseData.list.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            marginRight: isXSmall ? undefined : "350px",
          }}
        >
          No Record Found
        </p>
      ) : (
        <div
          style={
            isXSmall
              ? { width: "200vh", overflow: "scroll", marginTop: "25px" }
              : { marginTop: "25px" }
          }
          className={isXSmall ? "" : classes.rtable}
        >
          {editMode ? (
            <div className="form-content">
              <TableContainer className={isXSmall ? "" : classes.container}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className={classes.header} rowSpan={2}>STATE NAME</TableCell> */}
                      {user?.isClientConfiguration && (
                        <>
                          <TableCell
                            style={{
                              left: 0,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                              flex: 1,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Client Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 100,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 200,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Id
                          </TableCell>{" "}
                        </>
                      )}

                      {/* <TableCell className={classes.header} rowSpan={2}>LOCATION GPS ADDRESS</TableCell> */}
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 300 : 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Id
                      </TableCell>
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 400 : 100,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Name
                      </TableCell>
                      <TableCell
                        style={{
                          left: user?.isClientConfiguration ? 500 : 200,
                          position: "sticky",
                          width: "11x0px !important",
                          minWidth: "110px !important",
                          //  wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                          flex: 1,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        Designation
                      </TableCell>
                      {editResponse.list
                        ? editResponse &&
                          editResponse.dates &&
                          editResponse.dates.map(
                            (date: string, index: number) => {
                              return (
                                <TableCell
                                  className={classes.header}
                                  colSpan={4}
                                  style={{
                                    left: user?.isClientConfiguration
                                      ? 500
                                      : 200,
                                    position: "sticky",
                                    width: "110px !important",
                                    minWidth: "110px !important",
                                    //  wordBreak: "break-word",
                                    background: "#e6e6e6",
                                    zIndex: 999,
                                    flex: 1,
                                  }}
                                >
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                  })}
                                  {"-"}
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    month: "short",
                                  })}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                    <TableRow>
                      {editResponse.list
                        ? editResponse &&
                          editResponse.dates &&
                          editResponse.dates.map(
                            (employeeDate: any, index: number) => {
                              return (
                                <>
                                  <TableCell className={classes.tableHead}>
                                    In
                                  </TableCell>
                                  <TableCell className={classes.tableHead}>
                                    Out
                                  </TableCell>
                                  {/* <TableCell className={classes.tableHead}>DUTY</TableCell> */}
                                  <TableCell className={classes.tableHead}>
                                    Extra Duty
                                  </TableCell>
                                  <TableCell className={classes.tableHead}>
                                    Spent Hours
                                  </TableCell>
                                </>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ justifyContent: "center" }}>
                    {editResponse &&
                      editResponse.list &&
                      editResponse.list.map(
                        (employeeData: any, rowIndex: number) => {
                          return (
                            <TableRow hover role="checkbox">
                              {/* <TableCell align="left" className={classes.tableCell} >{employeeData && employeeData.stateName}</TableCell> */}
                              {user.isClientConfiguration && (
                                <>
                                  <TableCell
                                    style={{
                                      left: 0,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData && employeeData.clientName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 100,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 200,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                      textAlign: "center",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationId}
                                  </TableCell>
                                </>
                              )}

                              {/* <TableCell align="left" className={classes.tableCell} >-</TableCell> */}
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 300 : 0,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeCode}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 400 : 100,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeName}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 500 : 200,
                                  position: "sticky",
                                  width: "110px !important",
                                  minWidth: "110px !important",
                                  //  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.designationName}
                              </TableCell>
                              {resetCurrentDataIndex()}
                              {editResponse !== undefined &&
                              editResponse.dates !== undefined
                                ? editResponse.dates.map(
                                    (date: any, index: number) => {
                                      if (
                                        currentDataIndex <
                                          employeeData.data.length &&
                                        date ===
                                          employeeData.data[currentDataIndex]
                                            .datestr
                                      ) {
                                        // let data = employeeData.data;
                                        return (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={
                                                  employeeData.data[
                                                    currentDataIndex
                                                  ].inTimes
                                                }
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "inTimes"
                                                  );
                                                }}
                                              />
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={
                                                  employeeData.data[
                                                    currentDataIndex
                                                  ].outTimes
                                                }
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "outTimes"
                                                  );
                                                }}
                                              />
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={
                                                  employeeData.data[
                                                    currentDataIndex
                                                  ].overTime
                                                }
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "overTime"
                                                  );
                                                }}
                                              />
                                            </TableCell>

                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={
                                                  employeeData.data[
                                                    currentDataIndex
                                                  ].spentHours
                                                }
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "spentHours"
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                            {incCurrentDataIndex()}
                                          </>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={"-"}
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "inTimes"
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={"-"}
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "outTimes"
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={"-"}
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "overTime"
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              <input
                                                value={"-"}
                                                style={{ border: "none" }}
                                                onChange={(e: any) => {
                                                  handleClick(
                                                    rowIndex,
                                                    index,
                                                    e.target.value,
                                                    "spentHours"
                                                  );
                                                }}
                                              />
                                            </TableCell>
                                          </>
                                        );
                                      }
                                    }
                                  )
                                : null}
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{
                            textAlign: "center",
                            marginRight: isXSmall ? undefined : "350px",
                          }}
                        >
                          <TableCell
                            colSpan={9}
                            style={{
                              textAlign: "center",
                              marginRight: isXSmall ? undefined : "350px",
                            }}
                          >
                            No record found
                          </TableCell>
                          {/* <p style={{ textAlign: 'center', marginLeft: 400,fontSize: 14, textTransform: "capitalize", padding: 10, maxWidth: "100%",minWidth:"100%" }} >No record found</p> */}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                className={classes.savemleft32}
                color="primary"
                type="button"
                onClick={() => saveEditData()}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="form-content">
              <TableContainer className={isXSmall ? "" : classes.container}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell className={classes.header} rowSpan={2}>STATE NAME</TableCell> */}
                      {user.isClientConfiguration && (
                        <>
                          <TableCell
                            style={{
                              left: 0,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                              flex: 1,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Client Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 100,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Name
                          </TableCell>
                          <TableCell
                            style={{
                              left: 200,
                              position: "sticky",
                              width: "100px !important",
                              minWidth: "100px !important",
                              wordBreak: "break-word",
                              background: "#e6e6e6",
                              zIndex: 999,
                            }}
                            className={classes.header}
                            rowSpan={2}
                          >
                            Location Id
                          </TableCell>
                        </>
                      )}

                      {/* <TableCell className={classes.header} rowSpan={2}>LOCATION GPS ADDRESS</TableCell> */}
                      <TableCell
                        style={{
                          left: user.isClientConfiguration ? 300 : 0,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Id
                      </TableCell>
                      <TableCell
                        style={{
                          left: user.isClientConfiguration ? 400 : 100,
                          position: "sticky",
                          width: "100px !important",
                          minWidth: "100px !important",
                          wordBreak: "break-word",
                          background: "#e6e6e6",
                          textAlign: "center",
                          zIndex: 999,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        EMP Name
                      </TableCell>
                      <TableCell
                        style={{
                          left: user.isClientConfiguration ? 500 : 200,
                          position: "sticky",
                          width: "11x0px !important",
                          minWidth: "110px !important",
                          //  wordBreak: "break-word",
                          background: "#e6e6e6",
                          zIndex: 999,
                          flex: 1,
                        }}
                        className={classes.header}
                        rowSpan={2}
                      >
                        Designation
                      </TableCell>
                      {responseData.list
                        ? responseData &&
                          responseData.dates &&
                          responseData.dates.map(
                            (date: string, index: number) => {
                              return (
                                <TableCell
                                  className={classes.headerSticky}
                                  colSpan={4}
                                >
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                  })}
                                  {"-"}
                                  {new Date(date).toLocaleDateString("en-GB", {
                                    month: "short",
                                  })}
                                </TableCell>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                    <TableRow>
                      {responseData.list
                        ? responseData &&
                          responseData.dates &&
                          responseData.dates.map(
                            (employeeDate: any, index: number) => {
                              return (
                                <>
                                  <TableCell
                                    style={{
                                      border: "solid 1px lightgrey",
                                      borderRight: "solid 0px lightgrey",
                                      textAlign: "center",
                                      position: "sticky",
                                      zIndex: 10,
                                      top: 46,
                                    }}
                                  >
                                    In
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "solid 1px lightgrey",
                                      borderRight: "solid 0px lightgrey",
                                      textAlign: "center",
                                      position: "sticky",
                                      zIndex: 10,
                                      top: 46,
                                    }}
                                  >
                                    Out
                                  </TableCell>
                                  {/* <TableCell className={classes.tableHead}>DUTY</TableCell> */}
                                  <TableCell
                                    style={{
                                      border: "solid 1px lightgrey",
                                      borderRight: "solid 0px lightgrey",
                                      textAlign: "center",
                                      position: "sticky",
                                      zIndex: 10,
                                      top: 46,
                                    }}
                                  >
                                    Extra Duty
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "solid 1px lightgrey",
                                      borderRight: "solid 0px lightgrey",
                                      textAlign: "center",
                                      position: "sticky",
                                      zIndex: 10,
                                      top: 46,
                                    }}
                                  >
                                    Spent Hours
                                  </TableCell>
                                </>
                              );
                            }
                          )
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ justifyContent: "center" }}>
                    {responseData &&
                      responseData.list &&
                      responseData.list.map(
                        (employeeData: any, index: number) => {
                          let employee: any = {};
                          let dates: any = [];
                          employee["clientId"] =
                            (employeeData && employeeData.clientId) || "";
                          employee["clientName"] =
                            (employeeData && employeeData.clientName) || "";
                          employee["clientLocationName"] =
                            (employeeData && employeeData.clientLocationName) ||
                            "";
                          employee["clientLocationId"] =
                            (employeeData && employeeData.clientLocationId) ||
                            "";
                          employee["employeeCode"] =
                            (employeeData && employeeData.employeeCode) || "";
                          employee["employeeName"] =
                            (employeeData && employeeData.employeeName) || "";
                          employee["designationName"] =
                            (employeeData && employeeData.designationName) ||
                            "";
                          return (
                            <TableRow hover role="checkbox">
                              {/* <TableCell align="left" className={classes.tableCell} >{employeeData && employeeData.stateName}</TableCell> */}
                              {user.isClientConfiguration && (
                                <>
                                  {" "}
                                  <TableCell
                                    style={{
                                      left: 0,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData && employeeData.clientName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 100,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      left: 200,
                                      position: "sticky",
                                      width: "100px !important",
                                      minWidth: "100px !important",
                                      wordBreak: "break-word",
                                      background: "#e6e6e6",
                                      textAlign: "center",
                                    }}
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {employeeData &&
                                      employeeData.clientLocationId}
                                  </TableCell>
                                </>
                              )}

                              {/* <TableCell align="left" className={classes.tableCell} >-</TableCell> */}
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 300 : 0,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeCode}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 400 : 100,
                                  position: "sticky",
                                  width: "100px !important",
                                  minWidth: "100px !important",
                                  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.employeeName}
                              </TableCell>
                              <TableCell
                                style={{
                                  left: user.isClientConfiguration ? 500 : 200,
                                  position: "sticky",
                                  width: "110px !important",
                                  minWidth: "110px !important",
                                  //  wordBreak: "break-word",
                                  background: "#e6e6e6",
                                }}
                                align="left"
                                className={classes.tableCell}
                              >
                                {employeeData && employeeData.designationName}
                              </TableCell>
                              {resetCurrentDataIndex()}
                              {responseData !== undefined &&
                              responseData.dates !== undefined
                                ? responseData.dates.map(
                                    (date: any, index: number) => {
                                      let empPresentDate: any = {};
                                      if (
                                        currentDataIndex <
                                          employeeData.data.length &&
                                        date ===
                                          employeeData.data[currentDataIndex]
                                            .datestr
                                      ) {
                                        let data = employeeData.data;
                                        empPresentDate["datestr"] =
                                          employeeData.data[
                                            currentDataIndex
                                          ].datestr;
                                        empPresentDate["inTimes"] =
                                          helperFunc.timeWithAmPm(
                                            data[currentDataIndex].inTimes
                                          );
                                        empPresentDate["outTimes"] =
                                          helperFunc.timeWithAmPm(
                                            data[currentDataIndex].outTimes
                                          );
                                        empPresentDate["overTime"] =
                                          data[currentDataIndex].overTime;
                                        empPresentDate["spentHours"] =
                                          data[currentDataIndex].spentHours;

                                        let ret = (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              {helperFunc.timeWithAmPm(
                                                data[currentDataIndex].inTimes
                                              )}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              {helperFunc.timeWithAmPm(
                                                data[currentDataIndex].outTimes
                                              )}
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableCell}>-</TableCell> */}
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              {data[currentDataIndex].overTime}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              {
                                                data[currentDataIndex]
                                                  .spentHours
                                              }
                                            </TableCell>
                                          </>
                                        );
                                        incCurrentDataIndex();
                                        dates.push(empPresentDate);
                                        return ret;
                                      } else {
                                        return (
                                          <>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              -
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              -
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableCell}>-</TableCell> */}
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              -
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}
                                            >
                                              -
                                            </TableCell>
                                          </>
                                        );
                                      }
                                    }
                                  )
                                : null}
                              {(employee["data"] = dates) ? null : null}
                              {(() => {
                                list.push(employee);
                              })()}
                              {(editResponseData["dates"] = responseData.dates)
                                ? null
                                : null}
                              {(editResponseData["list"] = list) ? null : null}
                            </TableRow>
                          );
                        }
                      )}
                    {responseData &&
                      ((responseData.list && responseData.list.length === 0) ||
                        !responseData.list) && (
                        <TableRow
                          className={classes.table}
                          style={{
                            textAlign: "center",
                            marginRight: isXSmall ? undefined : "350px",
                          }}
                        >
                          <TableCell
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              marginRight: isXSmall ? undefined : "350px",
                            }}
                          >
                            No record found
                          </TableCell>
                          {/* <p style={{ textAlign: 'center', marginLeft: 400,fontSize: 14, textTransform: "capitalize", padding: 10, maxWidth: "100%",minWidth:"100%" }} >No record found</p> */}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}
      {/* </Paper> */}
    </div>
  );
};
export default AttendanceReport;
